<template>
  <div class="row">
    <!-- <div class="col-md-11 col-xl-12">
      <div class="d-flex align-items-md-center mb-2 flex-column flex-md-row">
        <div class="bg-white rounded p-4 d-flex flex-grow-1 flex-sm-grow-0">
          <form
            class="
              form
              d-flex
              align-items-md-center
              flex-sm-row flex-column flex-grow-1 flex-sm-grow-0
            "
          >
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
                <inline-svg src="media/svg/icons/General/Search.svg" />
              </span>
              <input
                type="text"
                v-model="VisitId"
                @input="onchangeSiteId()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search ID"
              />
            </div>
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
                <inline-svg src="media/svg/icons/General/Search.svg" />
              </span>
              <input
                type="text"
                v-model="DateTime"
                @input="onchangeDate()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search Date"
              />
            </div>
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
                <inline-svg src="media/svg/icons/General/Search.svg" />
              </span>
              <input
                type="text"
                v-model="Location"
                @input="onchangeLocation()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search Location"
              />
            </div>
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
                <inline-svg src="media/svg/icons/General/Search.svg" />
              </span>
              <input
                type="text"
                v-model="Problem"
                @input="onchangeProblem()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search Problem"
              />
            </div>
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
                <inline-svg src="media/svg/icons/General/Search.svg" />
              </span>
              <input
                type="text"
                v-model="Action"
                @input="onchangeAction()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search Action"
              />
            </div>
            <button
              type="submit"
              class="
                btn btn-dark
                font-weight-bold
                btn-hover-light-primary
                mt-3 mt-sm-0
                px-7
              "
            >
              Search
            </button>
          </form>
        </div>

        <div class="mt-4 my-md-0 mx-md-10">
          <a class="text-dark font-weight-bolder text-hover-primary"
            >Clear All</a
          >
        </div>
      </div>
    </div> -->
    <div class="col-md-11 col-xl-12">
      <b-card
        header-bg-variant="primary"
        header-text-variant="white"
        bg-variant="default"
        align="center"
        style="border-radius: 10px; border-color: transparent"
      >
        <div class="row d-flex ml-2 mt-8">
          <h3 class="card-label cardLabelTiket">Visit</h3>
        </div>
        <DxDataGrid
          :ref="my - data - grid"
          :data-source="itemsuser"
          key-expr="site_id"
          :show-borders="true"
          :word-wrap-enabled="false"
          :column-auto-width="false"
          :remote-operations="true"
          :column-hiding-enabled="true"
          @exporting="onExporting"
        >
        <DxColumnChooser
            :enabled="true"
        />
          <DxSearchPanel :visible="true" />
          <DxFilterRow :visible="true" />
          <DxExport :enabled="true" />
          <DxColumn data-field="site_id" caption="ID" :width="100" :hiding-priority="0" />
          <DxColumn
            data-field="visit_date"
            caption="Date & Time"
            :width="150"
            :hiding-priority="1"
          />
          <DxColumn data-field="poi_name" caption="Location" :hiding-priority="6" />
          <DxColumn data-field="problem" caption="Problem" :hiding-priority="2"/>
          <DxColumn data-field="problem_found" caption="Action" :width="290" :hiding-priority="5"/>
          <DxColumn
            data-field="status_akhir_pekerjaan"
            caption="Status"
            :width="150"
            :hiding-priority="3"
          />
          <DxColumn
            data-field="keterangan_status_pekerjaan"
            caption="Keterangan"
            :width="270"
            :hiding-priority="4"
          />
          <DxColumn :width="50" type="buttons" caption="Aksi">
            <DxButton icon="fa fa-eye" hint="Lihat" :onClick="iconEdit" />
          </DxColumn>
          <DxPaging :page-size="10" />
        </DxDataGrid>
        <b-modal
          v-model="showModalEdit"
          title="Detail"
          scrollable
          size="lg"
          id="edit-user-modal"
        >
          <b-container fluid>
            <b-row class="mb-2">
              <b-col cols="3">Site ID</b-col>
              <b-col>
                {{ detaildata.site_id }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Agen ID</b-col>
              <b-col>
                {{ detaildata.agent_id }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Nama Agent</b-col>
              <b-col>
                {{ detaildata.agent_name }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Poi ID</b-col>
              <b-col>
                {{ detaildata.poi_id }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Lokasi</b-col>
              <b-col>
                {{ detaildata.poi_name }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Longitude</b-col>
              <b-col>
                {{ detaildata.longitude }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Latitude</b-col>
              <b-col>
                {{ detaildata.latitude }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Type Tiket</b-col>
              <b-col>
                {{ detaildata.type_ticket }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Type Mesin</b-col>
              <b-col>
                {{ detaildata.type_mesin }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Sim Card</b-col>
              <b-col>
                {{ detaildata.sim_card_modem }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Type Mesin</b-col>
              <b-col>
                {{ detaildata.type_mesin }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Replace Part</b-col>
              <b-col>
                {{ detaildata.replace_part_1 }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Nomor Sim</b-col>
              <b-col>
                {{ detaildata.nomor_sim_card }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Led Zbox After</b-col>
              <b-col>
                {{ detaildata.led_zbox_after }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Led Zbox After</b-col>
              <b-col>
                {{ detaildata.led_zbox_after }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Led Zbox Before</b-col>
              <b-col>
                {{ detaildata.led_zbox_before }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Alamat</b-col>
              <b-col>
                {{ detaildata.street }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Problem</b-col>
              <b-col>
                {{ detaildata.problem }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Problem Detail</b-col>
              <b-col>
                {{ detaildata.problem_found }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Tindakan</b-col>
              <b-col>
                {{ detaildata.action }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Dari Jam - Sampai Jam</b-col>
              <b-col>
                {{ detaildata.start_time }} - {{ detaildata.end_time }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Tanggal Visit</b-col>
              <b-col>
                {{ detaildata.visit_date }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Keterangan</b-col>
              <b-col>
                {{ detaildata.keterangan_status_pekerjaan }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Status Pekerjaan</b-col>
              <b-col>
                <b-badge variant="success">{{
                  detaildata.status_akhir_pekerjaan
                }}</b-badge>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Foto</b-col>
              <b-col>
                <b-img
                  :src="detaildata.image_note"
                  fluid
                  alt="Fluid image"
                ></b-img>
              </b-col>
            </b-row>
          </b-container>
          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="danger"
                size="sm"
                class="float-right"
                @click="showModalEdit = false"
              >
                Close
              </b-button>
            </div>
          </template>
        </b-modal>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxSearchPanel,
  DxFilterRow,
  DxColumn,
  DxButton,
  DxExport,
} from "devextreme-vue/data-grid";
import { customers } from "../../mockjson";
import WebService from "../../core/services/api";
import EventBus from "../../core/services/common/EventBus";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import * as moment from "moment/moment";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxDataGrid,
    DxSearchPanel,
    DxFilterRow,
    DxColumn,
    DxButton,
    DxExport,
  },
  data() {
    return {
      dataSource: customers,
      detaildata: "",
      VisitId: "",
      DateTime: "",
      Location: "",
      Problem: "",
      Action: "",
      searchQuery: null,
      editvisit: false,
      showModalEdit: false,
      itemsuser: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      selectedstatdev: null,
      selectedstatusvisit: null,

      optionsstatusvisit: [
        { value: null, text: "Select Status Device" },
        { value: "a", text: "Active" },
        { value: "b", text: "Non Active" },
        { value: "c", text: "Pending Device" },
        { value: "d", text: "--" },
      ],
      optionsstatdev: [
        { value: null, text: "Select Status Visit" },
        { value: "a", text: "Priority" },
        { value: "b", text: "Priority" },
        { value: "c", text: "Priority" },
        { value: "d", text: "Priority" },
      ],
    };
  },

  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.items.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.fields.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    },
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    onchangeSiteId() {
      const filter = "site_id";
      const keyword = this.VisitId ? this.VisitId : "";
      if (this.VisitId !== "") {
        var filteredData = this.itemsuser.filter(function (obj) {
          return obj[filter] === keyword;
        });
        this.itemsuser = filteredData;
      } else {
        this.getDataTable();
      }
    },
    onchangeDate() {
      const filter = "visit_date";
      const keyword = this.DateTime ? this.DateTime : "";
      if (this.DateTime !== "") {
        var filteredData = this.itemsuser.filter(function (obj) {
          return obj[filter] === keyword;
        });
        this.itemsuser = filteredData;
      } else {
        this.getDataTable();
      }
    },
    onchangeLocation() {
      const filter = "poi_name";
      const keyword = this.Location ? this.Location : "";
      if (this.Location !== "") {
        var filteredData = this.itemsuser.filter(function (obj) {
          return obj[filter] === keyword;
        });
        this.itemsuser = filteredData;
      } else {
        this.getDataTable();
      }
    },
    onchangeProblem() {
      const value = "problem";
      const keyword = this.Problem ? this.Problem : "";
      if (this.Problem !== "") {
        var filteredData = this.itemsuser.filter(function (obj) {
          return obj[value] === keyword;
        });
        this.itemsuser = filteredData;
      } else {
        this.getDataTable();
      }
    },
    onchangeAction() {
      const filter = "problem_found";
      const keyword = this.Action ? this.Action : "";
      if (this.Action !== "") {
        var filteredData = this.itemsuser.filter(function (obj) {
          return obj[filter] === keyword;
        });
        this.itemsuser = filteredData;
      } else {
        this.getDataTable();
      }
    },
    info(item, index, button) {
      console.log(item, index, button);
      this.infoModal.title = `Detail`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    onExporting(e) {
      const tanggalSekarang = moment().format("DD-MM-YYYY");
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("VISIT");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `Visit_${tanggalSekarang}.xlsx`
          );
        });
      });
      e.cancel = true;
    },
    getDataTable() {
      let databody = { startDate: "2021-12-16", endDate: "2022-01-16" };
      WebService.getAllVisit(databody).then(
        (res) => {
          console.log(res.data);
          this.itemsuser = res.data;
          this.totalRows = res.data.totalCount;
        },
        (error) => {
          console.log(error);
          this.itemsuser = [];
          this.totalRows = 0;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    hapusvisit(index) {
      this.items.splice(index, 1);
    },
    iconEdit(e) {
      this.showModalEdit = true;
      this.detaildata = e.row.data;
    },
  },
};
</script>

<style></style>
